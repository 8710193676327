<template>
    <div class="page-box">
        <headBar background="#f5f5f5" ref="headerHeight" title="超鹿包月私教" left-arrow @click-left="newAppBack">
            <template slot="right">
                <img @click="showShare = true" class="share-icon"
                     src="https://img.chaolu.com.cn/ACT/invite-2022/share-icon.png" alt="">
            </template>
        </headBar>
        <app-page id="box" class="page-img">
            <van-sticky :offset-top="headerHeight">
                <div class="city-nav-box">
                    <div @click="cityChange(item)" :class="{active: item.cityId === currentCityId}" v-for="(item, index) in cityList" :key="index">
                        {{item.areaName}}卡
                    </div>
                </div>
            </van-sticky>



            <div class="page-h" v-if="selectItem.equityCardItemId">
                <div class="col-between-start" :style="`background-image: url(${allCardInfo.img})`">
                    <p class="col-start-start">
                        <span>{{ allCardInfo.equityCardName }}</span>
                        <span v-if="selectItem.isPerOnlyVenue && venueName">{{ venueName }}</span>
                        <span v-else-if="!(selectItem.isPerOnlyVenue)">{{cityName}}</span>
                    </p>
                    <div class="row-between-end">
                        <div class="row-start-end level-describe"><span>¥</span>{{ selectItem.groupType | levelTypeName }}档位</div>
                        <div v-if="selectItem.isPerOnlyVenue" class="row-start-center select-venue-btn">
                            <span v-if="distance">门店距你{{ distance }}</span>
                            <div @click="showCV(true)" class="row-center-center">切换购买门店</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="finished" class="page-h" style="padding: 0 4.266vw;box-sizing: border-box">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/empty_bg.png" alt=""
                     style="width: 100%;height: 71.2vw;">
            </div>
            <div class="nav-bg">
                <div v-if="selectItem.equityCardItemId" class="level-nav-title">选择私教价位</div>
                <div class="level-nav row-start-center">
                    <div @click="changeLevel(index)" v-for="(item, index) in allCardInfo.levels" :key="item.level"
                         :class="{active: levelType === item.level}" class="row-center-center">
                        {{ item.level | levelTypeName }}元
                    </div>
                </div>
            </div>

            <div class="card-box" v-show="selectItem.equityCardItemId">
                <div class="new-card-list">
                    <div @click="selectCard(item)" class="card-item"
                         :class="{active: item.equityCardItemId === selectItem.equityCardItemId}"
                         v-for="(item, index) in cardInfo.items" :key="index">
                        <div class="row-between-end card-item-top">
                            <p class="ellipsis card-name">{{ item.equityCardItemName }}</p>
                            <div v-if="item.originalPrice" class="originalPrice">¥{{item.originalPrice | priceFormat}}</div>
                        </div>
                        <div class="row-between-end">
                            <div class="col-start-start">
                                <div style="margin-bottom: 1.6vw" v-if="item.equityCardItemLightspot"
                                     class="item-tips ellipsis">{{ item.equityCardItemLightspot }}
                                </div>
                                <!-- 券后标签 -->
                                <p v-if="item.discountPrice" class="row-center-center couponAfterPrice">
                                    券后¥{{ item.discountPrice | priceFormat }}
                                </p>
                            </div>
                            <div class="price"
                                 v-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice">
                                <span>¥ </span>{{ item.firstPrice | priceFormat }}
                            </div>
                            <div class="price" v-else-if="item.salePrice">
                                <span>¥ </span>{{ item.salePrice | priceFormat }}
                            </div>
                        </div>
                        <!-- 专享价 -->
                        <p v-if="!(giveUpFirstBuy && item.equityCardItemId === selectItem.equityCardItemId) && item.firstPrice"
                           class="first-price">新客专享价，不可转赠</p>
                        <!-- 右上角标签 -->
                        <span v-if="item.activeLabel" class="discount-tag row-center-center">{{
                                item.activeLabel
                            }}</span>
                    </div>
                </div>
                <div class="card-tips">{{ selectItem.buyKnow }}</div>


                <div v-if="selectItem.equityCardItemId" class="card-box bottom-btn">
                    <div v-if="selectItem.firstPrice" class="first-price-box" :class="{active: giveUpFirstBuy}" @click="changeGiveUpFirstBuy">自愿放弃新客优惠，使用原价购买。</div>
                    <div v-if="selectedCouponId && voucherList.length" class="select-voucher" @click="showCoupon = true">
                        <div class="row-start-center" style="white-space: nowrap">
                            <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips.png" alt="">已减
                            <span>{{ selectedDiscount }}元</span>
                            <span class="row-start-center">，
                             <van-count-down @finish="timeFinish" :time="selectedCouponTips">
                              <template #default="timeData">
                                <span style="font-size: 3.2vw;line-height: 4.27vw;white-space: nowrap;">{{timeData.days}}天{{ timeData.hours }}时{{ timeData.minutes }}分</span>
                              </template>
                            </van-count-down>
                            </span>后失效
                        </div>
                        <p><span>{{ voucherList.length }}张</span><van-icon size="12px" name="arrow"/></p>
                    </div>
                    <div v-else-if="voucherList.length" class="select-voucher" @click="showCoupon = !(selectItem.firstPrice && !giveUpFirstBuy)">
                        <div class="row-start-center"><img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/renew/voucher_tips1.png" alt=""><span class="fw6">我的优惠券</span></div>
                        <p v-if="selectItem.firstPrice && !giveUpFirstBuy">不可与新客优惠同享</p>
                        <p v-else>{{ voucherList.length }}张<van-icon name="arrow"/></p>
                    </div>
                    <div class="buy-card-btn row-between-center" :class="{'send-bg': (selectedCouponId || (selectItem.firstPrice && !giveUpFirstBuy))}">
                        <h4><span class="s1">¥&nbsp;&nbsp;</span><span class="BebasNeueBold">{{ salePrice | priceFormat }}</span></h4>
                        <div><p @click="checkAwaitOrder()" class="row-center-center no-fast-click"></p></div>
                    </div>
                    <div class="protocol" :class="{shake: shake}">
                        <p @click="protocol = !protocol" :class="{active: protocol}"></p>
                        <div><span @click="protocol = !protocol">点击购买支付即代表您已阅读并同意</span><span class="s1" @click.stop="checkProtocol(true)">《超鹿包月私教购买服务协议》</span></div>
                    </div>
                </div>
                <div class="gift-detail"
                     v-if="selectItem.equityCardEquityVOList && selectItem.equityCardEquityVOList.length">
                    <div class="gift-detail-bottom">
                        <h4>权益详细说明</h4>
                        <p>*权益每30天解锁发放*</p>
                        <div class="gift-detail-item" v-for="(item, index) in selectItem.equityCardEquityVOList" :key="index">
                            <img :src="item.equityIntroduceImgUrl" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="selectItem.equityCardItemId" class="bottom-rule">
                <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/coach/rule2.png" alt="">
            </div>
        </app-page>
        <!--支付成功不能立即激活弹窗-->
        <van-popup v-model="showPayUnActivateSuccess" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png" alt="">
                    <div>购买成功！</div>
                </div>
                <div style="margin-top: 17.07vw" class="pay-share-btn row-between-center">
                    <div v-if="$route.query.fromOrder === '1'" class="grey-btn" @click="dontActivated">返回</div>
                    <div class="fw6" @click="jumpMyBagCopy">去卡包查看</div>
                </div>
                <div class="pay-box-tips">{{ `会员卡已到账至 “个人中心-卡包”` }}</div>
            </div>
            <img @click="showPayUnActivateSuccess = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="">
        </van-popup>
        <!--支付成功能激活弹窗-->
        <van-popup v-model="showPaySuccess" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box big">
                <div class="pay-box-title success">
                    <div class="row-center-center">
                        <img class="small-right" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png" alt="">
                        购买成功
                    </div>
                    <img class="pay-success-tips" src="https://img.chaolu.com.cn/ACT/schedule-2023/pay_success_tips.png" alt="">
                </div>
                <div class="pay-equit-box flex flex-wrap">
                    <img v-for="(item, index) in selectItem.equityCardEquityVOList" class="pay-box-item" :src="item.equityIconUrl" :key="index" alt="">
                </div>
                <div class="pay-share-btn row-between-center">
                    <div class="grey-btn" @click="dontActivated">暂不激活</div>
                    <div class="fw6" @click="immediateActivated">立即激活生效</div>
                </div>
                <div class="pay-box-tips">会员卡已到账至 “个人中心-卡包”</div>
                <div v-if="appTypeStr !== 'mini'" class="pay-box-tips" style="margin-top: 1.07vw">请升级至最新版APP查看详情</div>
            </div>
        </van-popup>


        <!--激活成功弹窗-->
        <van-popup v-model="showActivateSuccess" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box">
                <div class="pay-box-title">
                    <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/group12.png" alt="">
                    <div>激活生效成功!</div>
                </div>
                <div style="margin-top: 15vw" class="pay-share-btn row-between-center">
                    <div class="fw6" @click="immediateUse()">立即使用权益</div>
                </div>
            </div>
            <img @click="showActivateSuccess = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="">
        </van-popup>


        <!--人脸提示-->
        <van-popup v-model="showFaceTips" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false" @closed="closePaySuccess">
            <div class="pay-box face-tips">
                <div class="pay-box-title">
                    <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/coach/face.png" alt="">
                    <div>上课签到规则</div>
                    <p>使用超鹿包月私教预约课程<br>上课需本人人脸识别签到</p>
                </div>
                <div style="margin-top: 8.53vw" class="pay-share-btn row-between-center">
                    <div class="fw6" @click="checkBuy">我已知晓，继续购买</div>
                </div>
            </div>
            <img @click="showFaceTips = false" class="result-close" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/double11-2021/result-close.png" alt="">
        </van-popup>



        <!--错误弹窗提示-->
        <van-popup v-model="showError" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="error-box">
                <h4>提示</h4>
                <p>{{ errorMsg.msg }}</p>
                <div @click="closeError" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!--城市确认-->
        <van-popup v-model="showCheckCity" :overlay="true" style="background-color: transparent;width: 84vw;" closeable>
            <div class="error-box">
                <h4>提示</h4>
                <p>您当前购买的私教健身卡，仅限
                    <span style="font-weight: bold;color: #ED5C42">{{ cityName }}</span>
                    <span v-if="venueName" style="font-weight: bold;color: #ED5C42">【{{ venueName }}】</span>
                    <span v-else>所有门店</span>
                    使用，请确认后再付款！
                </p>
                <div @click="checkBuy" class="row-center-center">我知道了</div>
            </div>
        </van-popup>
        <!-- 协议弹窗 -->
        <van-popup v-model="showProtocol" :overlay="true" style="background-color: transparent"
                   :close-on-click-overlay="false">
            <div class="protocol-box">
                <h4>“超鹿运动”超鹿包月私教服务协议</h4>
                <div class="content">
                    <p class="bold">在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿运动健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买“超鹿包月私教”的会员有效。</p>
                    <p class="bold">一、关于超鹿包月私教</p>
                    <p>1、当用户成为“超鹿运动”的会员后，方可购买超鹿包月私教并使用，超鹿包月私教价格以购买时页面显示价格为准，购买成功后会员可在“我的-卡包”中查看超鹿包月私教相关信息。</p>
                    <p>2、超鹿包月私教按有效期时长分为月卡、季卡、半年卡及年卡。超鹿包月私教权益卡购买后将于会员自主激活时生效，有效期限以购卡时体现的期限为准，即自生效之日起至最后一日的23:59:59。会员仅可约有效期内的课程。</p>
                    <p>3、通过超鹿运动app、微信小程序外的第三方平台购买后在超鹿运动app上兑换的超鹿包月私教，自兑换时立即生效。</p>
                    <!-- <p>4、未激活或未生效的超鹿包月私教可直接在app上操作转赠给他人一次，若受赠人24小时内未领取，超鹿包月私教将原路返回赠送人账号，一旦受赠人领取超鹿包月私教，该卡将无法再次转赠、无法退款。</p> -->
                    <p>4、在有效期内的超鹿包月私教的账号在一台设备上登录后，该账号在5天内无法在别的设备上登录。</p>
                    <p class="red">5、超鹿包月私教仅限会员本人使用，使用超鹿包月私教预约课程前后需进行人脸识别签到打卡。</p>
                    <!-- 不可转赠， -->
                    <p>6、已激活、生效的超鹿包月私教原则上不可转赠，不可延期，不可退款，仅在下述情形下可申请办理退款或停卡：<br>
                        会员出现不宜运动的伤病或怀孕等情形，并提供医院证明的；
                    </p>
                    <p>7、如后台检测到某一账号异常，为了保证会员的账户安全，我们将立刻冻结该账号，后续将有客服联系该账号持有会员了解情况，如存在恶意使用账号的情形（包括但不限于将账号借给他人使用或利用账号进行其他谋利行为等），我们将对账号进行封号处理并追究持有人法律责任。针对首次违规操作账号行为，我们将作封号60天处理，二次及多次以上违规行为，将进行永久封号处理。</p>
                    <p class="bold">二、退款及停卡规则如下：</p>
                    <p>1、退款规则：</p>
                    <p>（1）已激活或已生效：退款金额=【标准价】-已使用私教权益价值-赠送的【团课券】面额价值*已使用数量-赠送的自助权益（实际使用时长/赠送的自助时长*赠送的自助时长价格）</p>
                    <p>①已使用私教权益价值： <br>
                    扣款金额=对应档位【标准价】/可用天数*生效后已经过天数。 <br>
                    若【私教抵扣金额】（即对应私教课程原价*使用私教月卡上的私教节数）超过该金额，则扣款金额为【私教抵扣金额】。</p>

                    <p>例一：299元档位私教月卡【标准价】为2980元，用户开卡17天仅上5节299元私教，且未用其它权益。则扣款逻辑： <br>
                    已消费金额为：（2980/30）*17=1688.67元 <br>
                    【私教抵扣金额】为：5*299=1495元 <br>
                    扣款金额为：1688.67元</p>

                    <p>例二（私教抵扣金额较大）：299元档位私教月卡【标准价】为2980元，用户开卡17天上了6节299元私教，且未用其它权益。则扣款逻辑： <br>
                    已消费金额为：（2980/30）*17=1688.67元 <br>
                    【私教抵扣金额】为：6*299=1794元 <br>
                    扣款金额为：1794元</p>

                    <p>②团课权益价值的计算方式=已使用团课价值（赠送的【团课券】面额价值*已使用数量）</p>

                    <p>例三：299元档位私教月卡【标准价】为2980元，用户开卡8天，上2节299元私教，已使用【满0-28元团课券】2张，未用自助权益。则扣款逻辑： <br>
                    已消费金额为：（2980/30）*8=794.67元 <br>
                    【私教抵扣金额】为：2*299=598元 <br>
                    已使用团课权益为：28*2=56元 <br>
                    扣款金额为：794.67元+56元=850.67元</p>


                    <p>③自助权益价值的计算方式=实际使用的分钟数/总计自助分钟数（900分钟）*自助价值（不足一分钟的向上取整）<br>
                        福厦区域自助卡价值375元<br>
                        广州区域自助卡价值420元
                    </p>
                    <p>例四：299元档位私教月卡【标准价】为2980元，用户开卡8天，上2节299元私教，已使用【自助时长300分钟】，未用团课权益。则扣款逻辑： <br>
                    已消费金额为：（2980/30）*8=794.67元 <br>
                    【私教抵扣金额】为：2*299=598元 <br>
                    已使用自助权益为：375*300/（900）=125元<br>
                    扣款金额为：794.67元+125元=919.67元</p>

                    <p>④会员在购买超鹿包月私教时获得的其他权益（包括但不限于赠送的赠送的自助小时和团课券）如尚未使用的，将于退款时自动失效并被收回。</p>

                    <p>（2）若可退金额等于或小于0时，则无法进行退款。</p>
                    <p>2、停卡规则：</p>
                    <p class="red">①月卡用户：不能停卡，除遇到不可抗力因素；</p>
                    <p class="red">②季卡及以上用户：每三个月可停卡一次，停卡时间不超过15天；</p>
                    <p class="bold">三.超鹿包月私教【标准价】一览：</p>

                    <p>240元档位【标准价】：月卡为2380元、季卡为6580元、半年卡为12880元、年卡为25080元。</p>
                    <p>299元档位【标准价】：月卡为2980元、季卡为8280元、半年卡为15980元、年卡为31480元。</p>
                    <p>360元档位【标准价】：月卡为3880元、季卡为10680元、半年卡为20880元、年卡为40980元。</p>
                    <p>420元档位【标准价】：月卡为4680元、季卡为12880元、半年卡为25280元、年卡为49380元。</p>

                    <p class="bold">四、超鹿包月私教权益</p>


                    <p>超鹿会员购买并开通激活超鹿包月私教卡、包季度、包半年、包年等同类私教卡后（以下统称包月及同类私教卡），享受权益和须遵守规则如下：</p>
                    <p>1、包月及同类私教卡约课权益：有效期内，会员每日可免费预约一节对应价格档位的私教课程。（部分教练或部分课程仅支持按次付费，不同价格档位课程可能需要补足差价，预约时请注意查看）。</p>
                    <p>2、会员使用包月私教卡，每次可免费预约一节私教课，该课程上完后，方可预约下一节；会员使用季卡、半年、年卡等，每次最多可同时免费预约三节课（每天限1节）。如果已经同时预约3节免费课程（含补差价后预约的课程），在上完就近的一节课程后，即可重新继续预约后面1节课，以此类推。也就是说，同时最多能看到的“未上免费课程”为3节。</p>
                    <p>3、使用包月及同类私教卡约私教课，课前人脸识别签到规则：超鹿包月及同类私教卡仅限购买会员本人使用，上课需本人人脸识别签到，签到时间为课前30分钟至课后60分钟，未签到将视为旷课，影响后续约课权益。</p>
                    <p>4、改签与退课权益与规则：会员约课后，可依据《私教退改签规则》进行退改签操作，未进行操作并缺席，属于无故旷课。《退改签规则》请见后文。</p>
                    <p class="red">5、会员使用约课权益约课后无故旷课，第一次给予短信提醒，第二次开始，将暂停当天私教免费预约权益，至次日0点后恢复。</p>
                    <p>6、包月及同类卡其他重要权益：</p>
                    <p>用户购卡激活后，权益有效期内，每30天提供以下三项权益</p>
                    <p>A、当日第二节课优惠：除每日可免费预约1节私教课外，当日第二节及更多私教课，可按次预约购买，并享受8.5折优惠。此优惠不与余额、礼品卡、课包券等其他优惠叠加使用。</p>
                    <p>B、赠送团课券：赠送4张28元团课券。具体优惠券使用规则可在我的-优惠券查看。</p>
                    <p>C、自助免费时长：可享15小时的全时段免费自助时长，有效期为30天。</p>

                    <p>《私教退改签规则》</p>
                    <p>改签规则</p>
                    <p>1.单笔订单仅限改签1次;</p>
                    <p>2.课前4小时可免费改签</p>
                    <p>3.课前4小时内至开课前:</p>
                    <p>1)若用户无课程免费时长内的进馆记录，支持改签</p>
                    <p>2)改签至当日其他空闲时段免费。</p>
                    <p>3)改签至第二天及以后，若拥有免费退改签权益次数，则可免费改签(每周1次免费的改签、退课机会，改签退课合并计算，每周一0点重置);若超出免费退改签次数，收取10%的手续费;</p>
                    <p>4.开课后至当天24点前: 若用户无课程免费时长内的进馆记录支持改签。改签至当日其他空闲时段免费。改签至第二天及以后，收取20%的手续费。</p>

                    <p>退课退款规则</p>
                    <p>1.开课前4小时退课，可免费退款;</p>
                    <p>2.开课前4小时内至开课前退课，若拥有免费退改签权益次数，则可免费退课(每周1次免费的改签、退课机会，改签退课合并计算，每周一0点重置);若超出免费退改签次数，收取10%的手续费</p>
                    <p>3.开课以后，不支持退款;</p>
                    <p>4.开课以后，改签至其他时间。该笔订单不支持再退款;</p>
                    <p>5.改签后再退课，订单所消耗的免费次数或支付的手续费不予退还。</p>


                    <p class="bold">五、关于会员服务及场馆使用规则，详见《用户协议》及《入场规则》。</p>
                    <p class="bold">六、“超鹿运动”对于本协议约定的内容具有最终解释权，同时根据国家法律法规变化及运营需要，“超鹿运动”有权对相关内容进行修改，会员在使用本协议所涉的服务时，可及时查阅了解。</p>
                    <p class="bold">七、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。</p>
                    <p>若会员对本协议有任何疑问，可以通过APP在线联系客服或致电客服，客服电话：400-700-5678。</p>
                </div>
                <div v-if="doneProtocol" @click="checkBuy()" class="row-center-center btn">我知道了</div>
                <div v-else class="row-center-center gray btn">请认真阅读以上内容({{ timeProtocol }})</div>
            </div>
        </van-popup>
        <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"
                      @payComplete="payComplete"></payPopupCard>
        <coupon :dateTips="expireDayTime" :unavailableList="unavailableList" :discount="selectedDiscount"
                :couponId="selectedCouponId" :list="voucherList" @confirm="confirmVoucher"
                :showCoupon.sync="showCoupon"></coupon>
        <!--  分享  -->
        <common-share
            :value="showShare"
            :shareParams="shareParams"
            :shareItem="['minifriend']"
            @close="showShare = false"
            @share-success="showShare = false"
        >
        </common-share>
        <equityVenue
            :current-city-id="currentCityId"
            :popShow.sync="storeShow"
            :can-use-venue="selectItem.venues"
            @popfun="choseStorePop"
            :chose-item="{
                areaId: '',
                venueId: [currentVenueId],
                lat: local ? local.lat : '',
                lng: local ? local.lng : '',
            }"></equityVenue>
    </div>
</template>
<script>
import userMixin from '@/mixin/userMixin';
import commonShare from "@/components/commonShare"
import headBar from '@/components/app/headBar'
import appPage from "@/common/components/appPage"
import payPopupCard from "@/components/pay-popup-card"
import coupon from "@/components/pay-popup-card/coupon"
import {
    appPaySuccess,
    initBack,
    newAppBack,
    gotoOrderList,
    closeWindow,
    appOpenWeb
} from "@/lib/appMethod";
import wx from "weixin-js-sdk"
import {getParam} from "@/lib/utils";
import equityVenue from "@/components/cl-city-venue/equity-venue.vue";

export default {
    data() {
        return {
            time: 0,
            showShare: false,
            giveUpFirstBuy: false,
            shareParams: {
                title: '购买超鹿包月私教，享超值福利。',
                miniImage: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/coach/coach.png',
                path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/sell-membership/coach',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            userPoster: '',
            headImg: '',
            nickName: '',
            showCoupon: false,
            showPaySuccess: false,
            showPayUnActivateSuccess: false,
            showActivateSuccess: false,
            showFaceTips: false,
            showRule: false,
            showAutoPay: false,
            showCheckCity: false, // 城市确认弹窗
            isShowCheckCityName: '', // 弹出城市确认弹窗的城市
            type: 0,
            selectItem: {}, // 当前选择卡下标
            cardInfo: {
                items: []
            },
            param: {},
            protocol: false, // 勾选用户协议
            showProtocol: false, // 勾选用户协议
            doneProtocol: false,//已阅读完协议
            timeProtocol: window.location.origin.indexOf('web.chaolu.com') > -1 ? 6 : 1, // 阅读倒计时
            isFirstContract: true,
            showError: false,
            shake: false,
            errorMsg: {},
            finished: false, // 接口是否已经加载完成
            showPayPopup: false,
            payData: {},
            payUrl: '',
            voucherList: [],
            unavailableList: [], //不可用列表
            selectedDiscount: 0,
            selectedCouponId: '',
            selectedCouponTips: '', // 失效提示
            expireDayTime: '', // 失效提示
            appVersion: 0, // app版本号
            ticketSource: 'WEB', // 购买来源 WEB 首页主动进入  SMS 短信进入
            currentCityId: '3', // 默认福州
            currentVenueId: '', // 选择生效门店
            cityName: '福州市',
            venueName: '',
            distance: '',
            showChoseCity: false, // 选择门店或城市
            headerHeight: 0,
            cityList: [],
            initCityList: [],  // 所有城市
            storeShow: false,
            initStoreList: [],
            choseItem: {},

            allCardInfo: {
                levels: []
            }, // 全部卡
            levelType: '', // 等级类型
        };
    },
    mixins: [userMixin],
    components: {
        equityVenue,
        appPage,
        headBar,
        payPopupCard,
        coupon,
        commonShare
    },
    filters: {
        priceFormat(v) {
            if (!v) {
                return 0
            }
            return parseFloat(v)
        },
        levelTypeName(v) {
            switch (v) {
                case 'TYPE_240':
                    return '240'
                case 'TYPE_299':
                    return '299'
                case 'TYPE_360':
                    return '360'
                case 'TYPE_420':
                    return '420'
                default:
                    return ''
            }
        }
    },
    mounted() {
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                this.showShare = false
            } else if (document.visibilityState === "hidden") {
                this.showShare = false
                this.showPaySuccess = false
                this.showPayUnActivateSuccess = false
                this.showActivateSuccess = false
            }
        })
        if(this.appTypeStr !== 'mini'){
            setTimeout(() => {
                this.headerHeight = this.$refs.headerHeight.$el.clientHeight || 0
            }, 1500)
        }
    },
    watch: {
        selectItem(value) {
            this.currentVenueId = ''
            this.venueName = ''
            this.distance = ''
        }
    },
    computed: {
        salePrice() {
            if (!this.giveUpFirstBuy && this.selectItem.firstPrice) {
                return this.selectItem.firstPrice
            }
            // 不选券使用限时售卖价
            if (!this.selectedDiscount || !this.selectedCouponId) {
                console.log(this.selectItem.salePrice)
                return this.selectItem.salePrice
            }
            // 最终售价
            let p = this.selectItem.salePrice || 0
            let voucherAfter = this.selectedDiscount || 0
            let price = (p - voucherAfter) < 0 ? 0 : (p - voucherAfter)
            return price.toFixed(2)
        }
    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'cityId', 'local'])
        this.currentCityId = await this.$getCityId() || '3'
        if (this.$route.query.venueId) {
            this.currentVenueId = this.$route.query.venueId
            let r = await this.getVenueInfo()
            if (r.cityId) {
                this.currentCityId = r.cityId
                this.cityName = r.cityName
            }
        }
        this.currentCityId = this.$route.query.cardCityId || this.currentCityId
        if (this.$route.query.ticketSource) {
            this.ticketSource = this.$route.query.ticketSource
        }
        this.getCityList()
        this.getCardList(true)
        if (this.appTypeStr === 'mini') {
            appPaySuccess().then(res => {
                // this.checkCanActivated()
                this.showPayUnActivateSuccess = true
            })
        }
        this.clickId = this.$route.query.clickId
        this.addUserAction('CONFIRM_EFFECTIVE_LEADS')

        const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership/coach`)
        this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
        wx.miniProgram.postMessage({
            data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
            },
        })
    },
    methods: {
        newAppBack,
        closeError() {
            this.showError = false
            if (this.errorMsg.skipOrder) {
                gotoOrderList()
            }
        },
        confirmVoucher(item) {
            if (item) {
                this.giveUpFirstBuy = true
                this.selectedDiscount = item.discount || 0
                this.expireDayTime = item.expireDayTime || 0
                this.selectedCouponId = item.id || ''
                let time = Math.round(item.expireDayTime * 1000 - (new Date().getTime())) || 0
                this.selectedCouponTips = time < 0 ? 0 : time
            }
        },
        changeGiveUpFirstBuy() {
            this.giveUpFirstBuy = !this.giveUpFirstBuy
            if (!this.giveUpFirstBuy) {
                this.selectedDiscount = 0
                this.selectedCouponId = ''
                this.selectedCouponTips = 0
                this.expireDayTime = 0
            }
        },
        payComplete(v) {

        },
        // 获取我的优惠券最高金额  及 可用和不可用列表
        getVoucherList(cardItemId) {
            this.$axios.post(`${this.baseURLApp}/voucher/equityCardVoucher`, {
                userId: this.userId,
                token: this.token,
                cardItemIds: [cardItemId],
            }, false).then(res => {
                if (res.data) {
                    let data = res.data?.list[0] ?? {}
                    this.voucherList = data.availableList || []  // 可用列表
                    this.unavailableList = data?.unavailableList || [] // 不可用列表
                    // 如果有首约价就不选择优惠券
                    if (!this.giveUpFirstBuy && this.selectItem.firstPrice) {
                        this.selectedDiscount = 0
                        this.selectedCouponTips = 0
                        this.expireDayTime = 0
                        this.selectedCouponId = ''
                        return false
                    }
                    this.selectedDiscount = data?.maxDiscount || 0
                    this.expireDayTime = data?.expireDayTime || 0
                    this.selectedCouponId = data?.maxDiscountVoucherId || ''
                    let time = Math.round(data?.expireDayTime * 1000 - (new Date().getTime())) || 0
                    this.selectedCouponTips = time < 0 ? 0 : time
                }
            })
        },
        // 倒计时结束刷新优惠券
        timeFinish() {
            // this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
        },
        getVenueInfo() {
            return new Promise(resolve => {
                this.$axios.post(`${this.baseURLApp}/newVenue/getVenueInfo`, {
                    userId: this.userId,
                    token: this.token,
                    venueId: this.currentVenueId
                }).then(res => {
                    resolve({cityId: res.data.cityId, cityName: res.data.cityName})
                }).catch(() => {
                    resolve({cityId: '3', cityName: "福州市"})
                })
            })
        },
        getCardList(isFirst) {
            this.$toast.loading({
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0,
                message: '加载中...'
            });
            this.$axios.post(`${this.baseURLApp}/equityCard/equityCardPerMall`, {
                userId: this.userId,
                token: this.token,
                cityId: this.currentCityId,
                lat: this.local ? this.local.lat : '',
                lng: this.local ? this.local.lng : '',
            }).then(res => {
                this.finished = true
                if(res?.data){
                    this.allCardInfo = res?.data || {}
                    let cardList = this.allCardInfo?.levels || []
                    if(this.$route.query.levelType) {
                        let item = cardList.find(r => {return r.level === this.$route.query.levelType})
                        if(item) {
                            this.cardInfo = item || {}
                        }else{
                            this.cardInfo = this.allCardInfo?.levels[0] || {}
                        }
                    }else{
                        this.cardInfo = this.allCardInfo?.levels[0] || {}
                    }
                    this.levelType = this.cardInfo?.level || ''
                    // 选中当前卡类型
                    this.selectItem = this.cardInfo?.items?.length ? this.cardInfo?.items[0] : {}
                    this.getVoucherList(this.selectItem.equityCardItemId) // 请求用户的优惠券
                }else{
                    this.allCardInfo = {}
                    this.selectItem = {}
                    this.cardInfo = {}
                    this.levelType = ''
                }
                this.$toast.clear()
                this.$forceUpdate()
            }).catch(() => {
                this.finished = true
            })
        },
        async selectCard(v) {
            this.selectItem = v
            this.giveUpFirstBuy = false
            await this.getVoucherList(v.equityCardItemId) // 请求用户的优惠券
        },
        // 切换价格等级
        changeLevel(index) {
            this.cardInfo = this.allCardInfo.levels[index] || {}
            this.levelType = this.cardInfo.level || ''
            this.selectItem = this.cardInfo.items.length ? this.cardInfo.items[0] : {}
        },
        // 校验是否有使用优惠券的待支付订单
        checkAwaitOrder() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/order/equityCardUser/monthlyCardToBePaidCouponVerification`, {
                userId: this.userId,
                userToken: this.token,
            }, false).then(res => {
                if (res.data) {
                    this.showCV()
                    this.$toast.clear()
                }
            }).catch((r) => {
                this.errorMsg = {msg: r.msg, showAutoRenew: r.data ? r.data.showAutoRenew : 0}
                this.showError = true
                this.$toast.clear()
            })
        },
        // 校验是否阅读完月卡协议
        checkProtocol(show = false) {
            this.isManualShowProtocol = show
            if (!this.doneProtocol) {
                this.showProtocol = true
                let timer = setInterval(() => {
                    this.timeProtocol--
                    if (this.timeProtocol < 1) {
                        clearInterval(timer)
                        this.doneProtocol = true
                        this.protocol = true
                    }
                }, 1000)
                return false
            } else {
                if (show) {
                    this.showProtocol = true
                }
                return true
            }
        },
        // 提示购买城市
        checkCity() {
            if (this.isShowCheckCityName && this.isShowCheckCityName === this.cityName) {
                this.checkBuy()
                return false
            }
            this.showCheckCity = true
            this.isShowCheckCityName = this.cityName
        },
        // 校验是否可以买月卡
        checkBuy() {
            this.showCheckCity = false
            if(this.isManualShowProtocol){
                this.showProtocol = false
                this.isManualShowProtocol = false
                return false
            }
            if (!this.checkProtocol()) {
                return false
            }
            this.showProtocol = false
            if (!this.protocol) {
                this.shake = true
                this.$toast({
                    message: '请仔细阅读并勾选购买协议！',
                    forbidClick: true,
                    onClose: () => {
                        this.shake = false
                    }
                })
                return false
            }
            if(!this.showFaceTipsed) {
                this.showFaceTips = true
                this.showFaceTipsed = true
                return false
            }
            let param = {
                equityCardItemId: this.selectItem.equityCardItemId,
                userId: this.userId,
                token: this.token,
                perVenueId: this.currentVenueId,
                source: 'SELF_BUY',
                giveUpFirstBuy: this.giveUpFirstBuy
            }
            this.$axios.post(`${this.baseURLApp}/equityCard/check/buy`, param, false).then(res => {
                if (res.data) {
                    this.buyCard()
                }
            }).catch((r) => {
                this.errorMsg = {
                    msg: r.msg,
                    showAutoRenew: r.data ? r.data.showAutoRenew : 0,
                    skipOrder: r?.data?.skip === 'ORDER_CENTER'
                }
                this.showError = true
            })
        },
        closePaySuccess() {
            // 关闭支付成功
        },
        // 购买
        buyCard() {
            // 点击购买页 上报
            this.addUserAction('COMPLETE_ORDER')
            let info = this.selectItem
            let paramUrl = getParam().url
            localStorage.setItem('paramUrl', paramUrl)
            let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/coach&userId=1&cityId=1&venueId=${this.$route.query.venueId || '31'}&cardCityId=${this.currentCityId}&fromOrder=${paramUrl?'1':''}`
            let payData = {
                subject: `${info.equityCardItemName}（${info.useValidity}天）（${this.$options.filters['levelTypeName'](info.groupType)}元档位）`,
                extendsJson: {
                    appVersion: '2.1.11',
                    seriesName: info.equityCardItemName,
                    ticketSource: this.ticketSource,
                    discountId: this.selectedCouponId,
                    giveUpFirstBuy: this.giveUpFirstBuy,
                    perUseVenueId: this.currentVenueId || ''
                },
                orderEquityCardCreateReq: {
                    source: 'SELF_BUY'
                },
                totalAmount: (this.giveUpFirstBuy || !info.firstPrice) ? info.salePrice : info.firstPrice,
                firstPrice: info.firstPrice,
                venueId: this.currentVenueId || '31',
                goodsNum: "1",
                isApp: '1',
                type: '24',
                selectedDiscount: this.selectedDiscount,
                discountId: this.selectedCouponId,
                userId: this.userId,
                token: this.token,
                cityId: this.currentCityId,
                extendsType: '4',
                mouldId: info.equityCardItemId,
                whetherPrivacy: 0,
                originalPriceStr: info.originalPrice,
                title: '购买内容',
                useTips: this.currentVenueId? `${this.venueName}可用`: `全${this.cityName}可用`,
            }
            if (this.appTypeStr === 'mini') {
                payData.wxPayType = 'applet'
            }
            this.showFaceTips = false
            this.showPayPopup = true
            this.payData = payData
            this.payUrl = url
            if (this.appTypeStr !== 'mini') {
                appPaySuccess().then(res => {
                    // this.showPaySuccess = true
                    // this.checkCanActivated()
                    this.showPayUnActivateSuccess = true
                    this.getCardList();
                })
            }
        },
        jumpMyBagCopy() {
            const url = `${window.location.origin}/#/month-card/my-card?userId=1`
            appOpenWeb('', url)
        },
        // 判断是否能立即激活，暂时移除
        checkCanActivated() {
            this.$axios.post(`${this.baseURLApp}/user/equityCard/isActiveEquityCardUser`, {
                type: 2,
                userId: this.userId,
                token: this.token,
            }).then(res => {
                this.equityCardUserId = res.data.equityCardUserId
                this.equityCardUserId ? this.showPaySuccess = true : this.showPayUnActivateSuccess = true
            })
        },
        // 立即激活
        immediateActivated() {
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            this.$axios.post(`${this.baseURLApp}/user/equityCard/open`, {
                userId: this.userId,
                token: this.token,
                userEquityCardId: this.equityCardUserId
            }).then((res) => {
                this.$toast.clear()
                this.showPaySuccess = false
                this.showActivateSuccess = true
            }).catch(() => {
                this.$toast.clear()
            })
        },
        // 立即使用
        immediateUse() {
            if (getParam().fromOrder === '1' || getParam().equityCardItemId) {
                this.jumpMyOrder()
            } else {
                this.jumpMyBagCopy()
            }
        },
        // 返回订单页
        dontActivated() {
            if (getParam().fromOrder === '1' || getParam().paramUrl) {
                this.jumpMyOrder()
            } else {
                this.showPaySuccess = false
                this.showPayUnActivateSuccess = false
            }
        },
        // 跳转订单页面
        jumpMyOrder() {
            console.log('触发返回')
            if (this.appTypeStr === 'mini') {
                const url = localStorage.getItem('paramUrl')
                wx.miniProgram.redirectTo({
                    url: decodeURIComponent(url)
                })
            } else {
                closeWindow()
            }
        },
        /**
         * 小程序广告转化
         * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
         */
        addUserAction(actionType) {
            if (!this.clickId) {
                return false
            }
            this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`, {
                actionUrl: 'superdeer-activity/sell-membership/self',
                clickId: this.clickId,
                actionType: actionType
            }, false).then(res => {
                console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
            }).catch(r => {
                console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
            })
        },

        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, {userId: this.userId}).then(res => {
                this.cityList = res.data
                let item = this.cityList.find(r => {return r.cityId === this.currentCityId})
                if(item){
                    this.cityName = item.areaName
                    this.currentCityId = item.cityId
                }
            })
        },
        cityChange(v) {
            this.cityName = v.areaName
            this.currentCityId = v.cityId
            this.giveUpFirstBuy = false
            this.getCardList()
            // 修改分享参数
            const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/sell-membership/coach`)
            this.shareParams.path = `/pages/webView/index?cardCityId=${this.currentCityId}&webUrl=${uu}`
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                },
            })
        },

        showCV(isChange = false) {
            this.isShowCheckCityName = ''
            this.manualChange = isChange
            if(isChange || this.selectItem?.isPerOnlyVenue && !this.currentVenueId){
                this.storeShow = true
            }else{
                this.checkBuy()
            }
        },
        //门店弹出窗口返回的数据
        choseStorePop(val, param, choseName) {
            this.storeShow = false
            this.storeTotal = choseName || this.storeTotal
            if (param) {
                let {venueId, venueName, areaId} = param
                this.choseItem.venueId = venueId || []
                this.choseItem.areaId = areaId
                this.currentVenueId = venueId[0]
                this.venueName = venueName
                this.distance = (this.selectItem.venues || []).find(r => r.venueId == this.currentVenueId)?.distance
                console.log()
                if(this.currentVenueId && !this.manualChange){
                    this.checkBuy()
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
.share-icon {
    width: 38px;
}

.new-card-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 32px 6px;

    .card-item {
        width: 334px;
        height: 186px;
        box-sizing: border-box;
        background: #F9FAFB;
        border-radius: 16px;
        border: 2px solid #ddd;
        flex-shrink: 0;
        margin-bottom: 18px;
        position: relative;
        padding: 32px 28px 0 20px;

        .first-price {
            text-align: right;
            line-height: 20px;
            font-size: 20px;
            margin-top: 12px;
            color: #FF6E00;
        }

        &:nth-child(2n) {
            margin-left: 18px;
        }

        &.active {
            background-color: #FFFDE3;
            border-color: #FFDE00;

            .couponAfterPrice {
                background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
            }

            .first-price {
                color: #A56309;
            }

            .item-tips {
                color: #A56309;
            }
        }

        .card-item-top {
            margin-bottom: 12px;
        }

        .card-name {
            color: #242831;
            font-size: 26px;
            font-weight: bold;
            line-height: 26px;
        }

        .item-tips {
            font-size: 20px;
            color: #6C727A;
            line-height: 20px;
            max-width: 8em;
        }

        .price {
            font-size: 64px;
            position: relative;
            top: 10px;
            color: #242831;
            //font-weight: bold;
            white-space: nowrap;
            font-family: BebasNeueBold;

            span {
                font-size: 28px;
            }
        }

        .originalPrice {
            font-size: 18px;
            text-decoration: line-through;
            color: #6C727A;
            font-weight: normal;
            line-height: 20px;
            white-space: nowrap;
        }

        .couponAfterPrice {
            height: 36px;
            background-color: #ECC589;
            border-radius: 4px;
            color: #A56309;
            font-size: 22px;
            font-weight: bold;
            padding: 0 4px;
            //margin-bottom: 12px;
            white-space: nowrap;
        }

        .discount-tag {
            position: absolute;
            right: -2px;
            top: -10px;
            height: 30px;
            background: #ED5C42;
            border-radius: 4px;
            font-size: 18px;
            color: #FFFFFF;
            padding: 0 8px;
            box-sizing: border-box;
        }
    }
}

.bottom-rule {
    padding: 0 32px 62px;
    img{
        display: block;
        width: 100%;
    }
    h4 {
        line-height: 36px;
        font-size: 36px;
        color: #232831;
    }

    p {
        margin-top: 14px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: #3C454E;
        white-space: pre-line;
    }
}

.page-box {
    min-height: 100vh;
    padding-bottom: 300px;
    background-color: #F5F5F5;
    & > div {
        width: 750px;
    }

    .top-menu {
        padding-left: 32px;
        overflow: hidden;
        box-sizing: border-box;
        background-color: white;
        height: 66px;
        width: 750px;
        margin-bottom: 32px;

        div {
            span {
                font-size: 40px;
                color: #3C454E;
                font-weight: 500;
                margin-right: 6px;
            }

            .play-icon {
                width: 32px;
                height: 32px;
                transform: rotateZ(90deg);
                transition: all .3s;
                transform-origin: center center;
                font-size: 24px;

                &.active {
                    transform: rotateZ(-90deg);
                    right: 0;
                }
            }

        }
    }

    .page-h {
        position: relative;
        width: 750px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        padding: 0 0 24px;

        & > div {
            width: 686px;
            height: 320px;
            border-radius: 16px;
            background: #fff no-repeat center center;
            background-size: 100% 100%;
            padding-bottom: 32px;
            box-sizing: border-box;

            & > p {
                padding: 24px 0 0 28px;
                font-size: 44px;
                line-height: 52px;
                color: white;
                font-family: PuHuiTi;
                span:first-child {
                    font-size: 52px;
                    line-height: 61px;
                    margin-bottom: 18px;
                }
            }
            & > div {
                color: #FFFFFF;
                padding: 28px 28px 0;
                .level-describe {
                    color: #FFFFFF;
                    line-height: 47px;
                    font-weight: bold;
                    font-size: 40px;

                    span {
                        font-size: 28px;
                        line-height: 40px;
                    }
                }

                .select-venue-btn {
                    border-radius: 8px;
                    background: rgba(0,0,0,0.26);
                    height: 50px;
                    line-height: 18px;
                    font-size: 18px;
                    div {
                        font-size: 22px;
                        font-weight: bold;
                        color: #242831;
                        border-radius: 8px;
                        background: #FFFFFF;
                        height: 50px;
                        padding: 0 20px;
                    }
                    span{
                        margin-right: 12px;
                        padding-left: 12px;
                    }
                }
            }
        }
    }

    .voucher {
        padding: 16px 32px 0;

        div {
            padding: 0 4px 0 120px;
            height: 40px;
            border-radius: 0 2px 2px 0;
            border: 1px solid #F03C18;
            border-left: none;
            font-size: 24px;
            color: #F03C18;
            font-weight: bold;
            background: url("https://img.chaolu.com.cn/MINI/icon/voucher-icon1.png") no-repeat left center;
            background-size: 104px 40px;
            box-sizing: border-box;

            span {
                margin-right: 4px;
            }
        }
    }

    .card-box {
        background-color: white;
        padding-top: 32px;
        &.bottom-btn {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 750px;
            padding: 0 0 calc(12px + env(safe-area-inset-bottom));
            z-index: 999;
        }

        .order-card-tips {
            width: 686px;
            height: 70px;
            background: #ECF3FE;
            color: #2D7EF5;
            font-size: 22px;
            border-radius: 16px;
            line-height: 70px;
            margin: 32px auto;
            padding-left: 24px;
        }

        .card-tips {
            font-size: 22px;
            line-height: 34px;
            color: #3C454E;
            padding: 0 32px;
            text-align: justify;
            word-break: break-all;
        }

        .card-list {
            display: flex;
            align-items: center;
            overflow-x: auto;
            padding: 16px 32px 32px;
            box-sizing: border-box;
            width: 100vw;

            &::-webkit-scrollbar {
                display: none;
            }

            .card-item {
                height: 296px;
                position: relative;
                width: 224px;
                background: #F9FAFB;
                border-radius: 16px;
                border: 2px solid #DDDDDD;
                flex-shrink: 0;
                box-sizing: border-box;

                &.card-item + .card-item {
                    margin-left: 16px;
                }

                &.active {
                    background: #FFFDE3;
                    border-color: #FFDE00;

                    & > p {
                        font-weight: 600;
                        background: linear-gradient(90deg, #FFF100 0%, #FFDE00 100%);
                        color: #242831;
                    }

                    .item-tips {
                        color: #A56309;
                    }
                }

                .item-tips {
                    font-size: 20px;
                    line-height: 20px;
                    color: #6C727A;
                    margin-top: 20px;
                }

                .discount-tag {
                    position: absolute;
                    right: -2px;
                    top: -10px;
                    height: 30px;
                    background: #ED5C42;
                    border-radius: 4px;
                    font-size: 18px;
                    color: #FFFFFF;
                    padding: 0 8px;
                    box-sizing: border-box;
                }

                & > div {
                    height: 256px;
                    width: 100%;
                    padding: 40px 12px 0;
                    box-sizing: border-box;

                    p {
                        font-size: 28px;
                        color: #242831;
                        font-weight: bold;
                        word-break: break-all;
                        text-align: justify;
                        line-height: 32px;
                        height: 64px;
                    }

                    .price {
                        margin-top: 6px;
                        font-size: 56px;
                        color: #242831;
                        font-weight: bold;
                        font-family: BebasNeueBold;

                        span {
                            font-size: 28px;
                        }
                    }

                    .originalPrice {
                        font-size: 22px;
                        text-decoration: line-through;
                        color: #6C727A;
                        font-weight: normal;
                    }
                }

                & > p {
                    background: #ECC589;;
                    color: #A56309;
                    font-weight: bold;
                    font-size: 24px;
                    height: 44px;
                    width: 224px;
                    position: absolute;
                    left: -2px;
                    bottom: -2px;
                    border-radius: 0 0 16px 16px;
                }
            }
        }

        .select-voucher {
            width: 100vw;
            height: 72px;
            background: #FDEEEC;
            margin: 0 auto 0;
            padding: 0 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                height: 32px;
                margin-right: 12px;
            }

            & > div {
                font-size: 24px;

                span {
                    color: #F03C18;
                    //font-weight: bold;
                }
            }

            & > p {
                display: flex;
                align-items: center;
                font-size: 24px;
                //color: #F03C18;
                span {
                    margin-left: 4px;
                }
            }
        }

        .buy-card-btn {
            width: 686px;
            height: 112px;
            margin: 32px auto 20px;
            //background-color: #242831;
            display: flex;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            position: relative;
            border-radius: 24px;
            background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/month/btn1.png"); /* 20231225  btn.png可赠送 修改为 btn1.png不可赠送 */
            &.send-bg{
                background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/month/btn1.png");
            }
            & > h4 {
                font-size: 64px;
                color: #F7DFB4;
                width: 360px;
                padding-left: 80px;
                box-sizing: border-box;
                font-weight: bold;
                position: relative;
                top: 2px;

                .s1 {
                    font-family: BebasNeueBold;
                    font-weight: normal;
                    font-size: 32px;
                }

                .s2 {
                    font-family: BebasNeueBold;
                }

                .s3 {
                    font-weight: normal;
                    color: #FFFFFF;
                    font-size: 24px;
                    margin-left: 12px;
                    text-decoration: line-through;
                }
            }

            & > div {
                display: flex;

                //padding-right: 8px;
                p {
                    width: 314px;
                    height: 112px;
                    //background: linear-gradient(90deg, #FFEF84 0%, #FFDE00 100%);
                    border-radius: 22px;
                    font-size: 32px;
                    font-weight: bold;
                    color: #242831;

                    &:last-child {
                        margin-left: 12px;
                    }
                }
            }

            &.purchased {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button1.png");
            }

            &.no-start {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button3.png");
            }

            &.end {
                background-image: url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-button2.png");
            }
        }

        .protocol {
            display: flex;
            align-items: flex-start;
            padding: 0 32px;

            &.shake {
                animation: shake 1s;
            }

            p {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                background: url("https://img.chaolu.com.cn/ACT/invite-2022/icon7.png") no-repeat center center;
                background-size: 100% 100%;

                &.active {
                    background-image: url("https://img.chaolu.com.cn/ACT/invite-2022/icon6.png");
                }
            }

            div {
                font-size: 18px;
                line-height: 26px;
                color: #707072;
                padding-top: 7px;

                .s1 {
                    font-weight: bold;
                    color: #232831;
                }
            }
        }

        .gift-detail {
            margin-top: 32px;

            .gift-detail-top {
                background: #2A2D3C;
                padding: 48px 32px 20px;

                h4 {
                    font-size: 28px;
                    line-height: 28px;
                    color: #FFFFFF;
                    margin-bottom: 32px;
                    text-align: center;
                }

                & > div {
                    flex-wrap: wrap;

                    div {
                        position: relative;
                        margin-bottom: 20px;
                        margin-right: 16px;

                        &:nth-child(3n) {
                            margin-right: 0;
                        }

                        span {
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 34px;
                            padding: 0 18px;
                            background: #FFDE00;
                            border-radius: 12px 0 12px 0;
                            color: #242831;
                            font-size: 20px;
                        }

                        img {
                            width: 218px;
                            height: 196px;
                            border-radius: 12px;
                            display: flex;
                        }

                        p {
                            width: 218px;
                            font-size: 20px;
                            line-height: 28px;
                            color: #FFFFFF;
                            font-weight: 500;
                            margin-top: 18px;
                        }
                    }
                }
            }

            .gift-detail-bottom {
                background: #F5F5F5;
                padding: 48px 32px;

                h4 {
                    font-size: 36px;
                    line-height: 36px;
                    color: #232831;
                }

                & > p {
                    font-size: 24px;
                    color: #3C454E;
                    line-height: 24px;
                    margin-top: 20px;
                }

                .gift-detail-item {
                    width: 686px;
                    border-radius: 16px;
                    margin-top: 32px;
                    position: relative;
                    img {
                        flex: none;
                        width: 100%;
                        display: block;
                        //height: auto;
                        //-o-object-fit: cover;
                        //object-fit: cover;
                    }
                }
            }
        }
    }

}

.protocol-box {
    width: 630px;
    height: 900px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;

    h4 {
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }
    .content{
        &::-webkit-scrollbar {
            display: none;
        }

        height: 570px;
        overflow-x: auto;
        margin: 32px auto 56px;
        width: 630px;
        padding: 10px 32px;
        font-size: 24px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
        white-space: pre-line;
        box-sizing: border-box;
        text-align: justify;
        p {
            text-indent: 2em;
            margin-bottom: 16px;
        }
        .bold{
            font-weight: bold;
        }
        .red{
            color: red;
        }
    }


    .btn {
        margin: 0 auto;
        font-weight: bold;
        width: 566px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;

        &.gray {
            background: #9AA1A9;
        }
    }
}

.error-box {
    width: 630px;
    background: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
    padding-bottom: 48px;

    & > span {
        display: block;
        text-align: center;
        margin-bottom: 24px;
        font-size: 28px;
        font-weight: bold;
        color: #0a7aff;
    }

    h4 {
        font-size: 36px;
        font-weight: 600;
        color: #242831;
        line-height: 36px;
        text-align: center;
        padding-top: 58px;
    }

    p {
        text-align: center;
        margin: 32px auto 56px;
        width: 510px;
        font-size: 28px;
        font-weight: 400;
        color: #3C454E;
        line-height: 44px;
    }

    div {
        margin: 0 auto;
        font-weight: bold;
        width: 510px;
        height: 96px;
        background: #FFDE00;
        border-radius: 8px;
        font-size: 32px;
        color: #242831;
        line-height: 32px;
    }
}

// 支付成功弹窗
.pay-box {
    width: 590px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white url("https://chaolucoachwebstatic.chaolu.com.cn/static/img/month-card-2022/buy-success-bg.png") no-repeat center top;
    background-size: 100% 554px;
    border-radius: 16px;
    overflow: hidden;
    padding-bottom: 40px;
    &.big {
        width: 622px;
    }
    &.face-tips{
        background: white;
        .pay-box-title{
            font-size: 36px;
            img{
                padding: 0 0 32px;
                width: 400px;
                height: 400px;
            }
            p{
                font-size: 26px;
                color: #242831;
                line-height: 44px;
                width: 360px;
                text-align: center;
                margin: 24px auto 0;

            }
        }
    }
    .pay-box-title {
        &.success {
            padding: 64px 0 0;
        }
        .pay-success-tips {
            width: 444px;
            height: 28px;
            display: block;
            margin: 0 auto;
            padding: 48px 0 0;
        }
        .small-right {
            width: 64px;
            height: 64px;
            padding: 0;
            margin: 0 16px 0 0;
        }
        img {
            width: 128px;
            height: 128px;
            display: block;
            margin: 0 auto;
            padding: 64px 0 48px;
        }

        div {
            font-weight: bold;
            color: #242831;
            font-size: 36px;
            line-height: 42px;
            text-align: center;
        }
    }

    .pay-box-text {
        font-size: 28px;
        color: #242831;
        line-height: 40px;
        margin-top: 24px;
        text-align: center;
    }

    .pay-box-tips {
        font-size: 22px;
        color: #6C727A;
        line-height: 26px;
        text-align: center;
        width: 460px;
        margin: 40px auto 0;

        .s1 {
            color: #FF6E00;
            font-size: 22px;
        }
    }

    .pay-share-btn {
        div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 96px;
            border-radius: 8px;
            border: 1px solid #FFDE00;
            box-sizing: border-box;
            background: #FFDE00;
        }

        .grey-btn {
            width: 206px;
            flex: none;
            margin: 0 24px 0 0;
            background-color: #fff;
            border: 1px solid #CCCCCC;
        }

        padding: 0 32px;
        height: 96px;
        margin: 48px auto 0;
        color: #242831;

    }
}

.result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
}

// 海报样式
.share-poster-cont {
    position: relative;
    overflow: hidden;
    //width: 100 * @w;
    width: 654px;
    height: 968px;
    //width: 103.2vw;
    margin: 0 auto;
    box-sizing: border-box;

    img.share-bg {
        width: 100%;
        height: 100%;
        display: block;
    }
}

.share-user {
    position: absolute;
    left: 32px;
    top: 32px;
    display: flex;
    align-items: center;

    img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        margin-right: 24px;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
    }

    .use-header-name {
        font-size: 28px;
        font-weight: bold;
        color: #242831;
        line-height: 40px;
        //width: 45 * @w;
        width: 15em;
    }

    .use-header-text {
        font-size: 22px;
        font-weight: 400;
        color: #242831;
        line-height: 26px;
        margin-top: 12px;
    }
}

.share-poster-cont .poster-code-cont {
    position: absolute;
    right: 32px;
    bottom: 32px;
    width: 160px;
    height: 160px;
    padding: 8px;
    background: rgba(255, 255, 255, 0.35);;
    //box-sizing: border-box;
    border-radius: 16px;
}

.share-poster-cont .poster-code-img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: block;
    background-color: white;
    box-sizing: border-box;

}

.city-nav-box {
    display: flex;
    align-items: center;
    padding: 16px 32px 24px;
    width: 100vw;
    box-sizing: border-box;
    overflow-x: auto;
    background-color: #f5f5f5;
    div {
        //width: 148px;
        flex-shrink: 0;
        height: 56px;
        background: #fff;
        border-radius: 8px;
        color: #666666;
        font-size: 22px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #999999;
        margin-right: 24px;
        box-sizing: border-box;

        &.active {
            font-weight: bold;
            color: #242831;
            border-color: #242831;
        }
    }
}

.first-price-box {
    width: 750px;
    height: 64px;
    display: flex;
    align-items: center;
    line-height: 23px;
    font-size: 23px;
    color: #267DFF;
    padding-left: 76px;
    box-sizing: border-box;
    background: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_select.png") no-repeat #ECF3FE 32px center;
    background-size: 40px 40px;

    &.active {
        background-image: url("https://img.chaolu.com.cn/ACT/sell-membership-2022/price_selected.png");
    }
}
.nav-bg{
    background: linear-gradient(180deg, #F5F5F5 0%, #F2F2F2 100%);
}
/* 等级切换 */
.level-nav-title {
    padding: 16px 32px 28px;
    color: #242831;
    line-height: 28px;
    font-size: 28px;
    font-weight: bold;
}

.level-nav {
    width: 750px;
    box-sizing: border-box;
    overflow-x: auto;

    & > div {
        width: 25%;
        height: 80px;
        font-size: 27px;
        color: #242831;
        line-height: 26px;
        border-radius: 16px 16px 0 0;
        box-sizing: border-box;
        & + div {
            //margin-left: 18px;
            position: relative;
            &:after{
                content: '';
                width: 1px;
                height: 28px;
                position: absolute;
                left: 0;
                top: 26px;
                background-color: #9AA1A9;
            }
        }

        &.active {
            background-color: white;
            font-weight: bold;
            color: #A56309;
            &:after{
                display: none;
            }
            & + div{
                &:after{
                    display: none;
                }
            }
        }
        &:nth-child(4n){
            border-radius: 16px 0 0 0;
        }
        &:first-child{
            border-radius: 0 16px 0 0;
        }
    }
}
.pay-equit-box {
    margin: 40px 0 0;
    padding: 0 0 0 34px;

    .pay-box-item {
        width: 123px;
        height: 128px;
        margin: 0 22px 20px 0;
    }
}
</style>
